export default {
  init() {
    // JavaScript to be fired on all pages
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        document.getElementById('header-logo').style.width = '300px';
        document.getElementById('blue-logo').style.width = '300px';
      } else {
        document.getElementById('header-logo').style.width = '450px';
        document.getElementById('blue-logo').style.width = '450px';
      }
    }

    $(document).ready(function () {
      var prevScrollpos = window.pageYOffset;
      window.onscroll = function () {
        var scrollPosition = $(this).scrollTop();
        if (scrollPosition >= 500) {
          var currentScrollPos = window.pageYOffset;
          if (prevScrollpos > currentScrollPos) {
            document.getElementById('header').style.top = '0';
          }
          else {
            document.getElementById('header').style.top = '-161px';
          }
          prevScrollpos = currentScrollPos;
        }
      }
    });

    $(document).ready(function () {
      $('#nav-icon3').click(function () {
        $(this).toggleClass('open');
      });
    });

    $('#OpenNav').click(function () {
      $(this).toggleClass('open');
      if ($('#mySidenavMobile').width() == '0') {
        document.getElementById('mySidenavMobile').style.width = '100%';
        document.getElementById('hamburger-2').style.background = '#ffc107';
        document.getElementById('hamburger-3').style.background = '#ffc107';
        $('#blue-logo').removeClass('display-none');
        $('#header-logo').addClass('display-none');
      }
      else {
        document.getElementById('mySidenavMobile').style.width = '0';
        document.getElementById('hamburger-2').style.background = '#fff';
        document.getElementById('hamburger-3').style.background = '#fff';
        $('#blue-logo').addClass('display-none');
        $('#header-logo').removeClass('display-none');
      }
    });

    $(document).ready(function () {
      function myFunction(x) {
        var s = document.getElementById('mySidenavMobile');
        if (x.matches) { // If media query matches
          s.style.width = '0px';
          $('#mySidenavMobile').style.width = '0';
        }
      }

      var x = window.matchMedia('(min-width: 991px)')
      myFunction(x) // Call listener function at run time
      x.addListener(myFunction) // Attach listener function on state changes
    });

    $(document).ready(function () {
      $('#nav-icon3').click(function () {
        if ($('#mySidenav').hasClass('display-none')) {
          $('#mySidenav').toggleClass('side-nav-show').slideDown('400');
          $('#mySidenav').removeClass('display-none');
          document.getElementById('hamburger2').style.background = '#ffc107';
          document.getElementById('hamburger3').style.background = '#ffc107';
          $('#blue-logo').removeClass('display-none');
          $('#header-logo').addClass('display-none');
        }
        else if ($('#mySidenav').hasClass('side-nav-show')) {
          $('#mySidenav').toggleClass('display-none').slideUp('400');
          $('#mySidenav').removeClass('side-nav-show');
          document.getElementById('hamburger2').style.background = '#fff';
          document.getElementById('hamburger3').style.background = '#fff';
          $('#blue-logo').addClass('display-none');
          $('#header-logo').removeClass('display-none');
        }
      });
    });

    $('.carousel-education').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,

      prevArrow: '<img class="a-left control-c prev slick-prev" src="../../wp-content/themes/sage9_totalcare/resources/assets/images/arrow-blue-left.svg">',
      nextArrow: '<img class="a-right control-c next slick-next" src="../../wp-content/themes/sage9_totalcare/resources/assets/images/arrow-blue-right.svg">',

      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 1,
            slidesToShow: 1,
          },
        },
      ],
    });

    $(document).ready(function () {
      var acc = document.getElementsByClassName('accordion');
      var i;

      for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener('click', function () {
          this.classList.toggle('active');
          var panel = this.nextElementSibling;
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
          } else {
            panel.style.maxHeight = panel.scrollHeight + 'px';
          }
        });
      }
    });

    $(document).ready(function () {
      /* ----- JavaScript ----- */
      window.onload = function () {
        /* Cache the popup. */
        var popup = document.getElementById('popup');

        /* Fade the popup in */
        setTimeout(() => popup.classList.add('fade-in'));

        $('#close').click(function () {
          $('#popup').toggleClass('d-none');
        });

      };
    });

    $('#watch_button').click(function () {
      $('#display_video').removeClass('d-none');
    });

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
